<svelte:options tag="svelte-datagrid" />
<script>
	import { createEventDispatcher, onDestroy, onMount } from "svelte";
	import { get_current_component } from "svelte/internal";
	import Combo from "./Combo.svelte";
	import InputNumber from "./InputNumber.svelte";
	import SelectSvelte from "./SelectSvelte.svelte";
	import TextEditor from "./TextEditor.svelte";
	import Calendario from "./Calendario.svelte";
	import DatePicker from "./DatePicker.svelte";	
	import {clickOutside} from './clickOutside.js';

	export let dataProvider = [];
	export let columns = [];
	export let absolute = true;
	export let mostrarMensagemVazio = false;
	export let mensagemVazio = "Não existem registros";
	export let sortArray = true;
	export let showHeader = true;
	export let smaller = false;

	const component = get_current_component();
	const svelteDispatch = createEventDispatcher();
	const dispatch = (name, detail) => {
		svelteDispatch(name, detail);
		component.dispatchEvent &&
			component.dispatchEvent(new CustomEvent(name, { detail }));
	};

	let registroSelecionado = {};
	let jaDisparou = false;

	onMount(async () => {});

	onDestroy(() => {});

	export function refresh() {		
		if (columns) {
			// columns = columns;
			for (let i = 0; i < columns.length; i++) {
				columns[i]._refresh = new Date().toString();				
			}
		}
		if (dataProvider) {
			// dataProvider = dataProvider;
			for (let i = 0; i < dataProvider.length; i++) {
				dataProvider[i].__id = i + 1;
				dataProvider[i]._refresh = new Date().toString();
			}
		}
	}

	export function selecionarRegistro(registro) {
		let idx = dataProvider.indexOf(registro);
		clickRegistro(registro, idx);
	}

	function clickRegistro(registro, idx) {		
		if (!registro.__id) {
			registro.__id = idx + 1;
		}
		registroSelecionado = registro;
		dispatch("onSelect", registroSelecionado);
	}

	function changeCheck(registro, coluna, event) {
		if (event.target.checked) {
			registro[coluna.dataField] = coluna.checkbox[0];
		} else {
			registro[coluna.dataField] = coluna.checkbox[1];
		}
		if (coluna.change) {
			coluna.change(registro, coluna, registro[coluna.dataField]);
		}
		if (coluna.click) {
			coluna.click(registro, coluna, registro[coluna.dataField]);
		}
	}

	function changeCheckAll(coluna, event) {
		for (let i = 0; i < dataProvider.length; i++) {
			if (event.target.checked) {
				dataProvider[i][coluna.dataField] = coluna.checkbox[0];
			} else {
				dataProvider[i][coluna.dataField] = coluna.checkbox[1];
			}
		}
		if (coluna.selectAllEvent) {
			coluna.selectAllEvent();
		}
	}

	function clickRenderer(registro, coluna, event) {
		if (coluna.click) {
			coluna.click(registro, coluna, dataProvider);
		}
	}

	function duploClique(registro) {
		dispatch("doubleclick", registro);
	}

	function onScroll(event) {
		const body = event.target;
		if (
			!jaDisparou &&
			body.offsetHeight + body.scrollTop >= body.scrollHeight * 0.95
		) {
			jaDisparou = true;
			dispatch("finalScroll", {});
			setTimeout(() => {
				jaDisparou = false;
			}, 1000);
		}
	}

	function validateNumber(e) {
		// let input = String.fromCharCode(e.charCode);
		// const reg = /^\d+$/;
		// if (!reg.test(input)) {
		// 	e.preventDefault();
		// }
	}

	async function onBlurChange(column, registro, casas) {
		if (registro[column.dataField]) {
			let n = Number(registro[column.dataField]);
			registro[column.dataField] = Number(Number(n).toFixed(casas));
		} else {
			registro[column.dataField] = 0;
		}
		refresh();
		if (column.valida) {
			const valido = await column.valida(registro, column, dataProvider);
			if (!valido) {
				// this.vars.showMessage(valido.mensagem);
				if ("rollback" in column) {
					column.rollback(registro, column);
				}
			} else {
				column.commit(registro, column);
			}
		}
	}

	function clickColuna(coluna) {
		if (coluna.dataField) {
			if (coluna.ordenado) {
				if (coluna.ordenado == "C") {
					coluna.ordenado = "D";
				} else {
					coluna.ordenado = "C";
				}
			} else {
				tirarOrdenacaoOutrasColunas(coluna);
				coluna.ordenado = "C";
			}
			columns = columns;
			if (sortArray) {
				aplicarOrdenacao(coluna);
			}
			dispatch("onSortColumn", coluna);
		}
	}

	function aplicarOrdenacao(coluna) {
		let sortModifier = coluna.ordenado == "C" ? 1 : -1;
		let sortAsDate = false;
		let sortAsDateTime = false;

		let sort = (a, b) => {
			if (
				a[coluna.dataField] &&
				b[coluna.dataField] &&
				a[coluna.dataField].toString().length == 10 &&
				a[coluna.dataField].toString().indexOf("/") == 2 &&
				a[coluna.dataField].toString().indexOf("/", 3) == 5
			) {
				sortAsDate = true;
			}
			if (
				a[coluna.dataField] &&
				b[coluna.dataField] &&
				a[coluna.dataField].toString().length == 19 &&
				a[coluna.dataField].toString().indexOf("/") == 2 &&
				a[coluna.dataField].toString().indexOf("/", 3) == 5 &&
				a[coluna.dataField].toString().indexOf(":") == 13
			) {
				sortAsDateTime = true;
			}
			if (sortAsDate) {
				let compA = new Date(
					Number(a[coluna.dataField].substr(6, 4)),
					Number(a[coluna.dataField].substr(3, 2)) - 1,
					Number(a[coluna.dataField].substr(0, 2))
				).getTime();
				let compB = new Date(
					Number(b[coluna.dataField].substr(6, 4)),
					Number(b[coluna.dataField].substr(3, 2)) - 1,
					Number(b[coluna.dataField].substr(0, 2))
				).getTime();
				return compA < compB
					? -1 * sortModifier
					: compA > compB
					? 1 * sortModifier
					: 0;
			} else if (sortAsDateTime) {
				let compA = new Date(
					Number(a[coluna.dataField].substr(6, 4)),
					Number(a[coluna.dataField].substr(3, 2)) - 1,
					Number(a[coluna.dataField].substr(0, 2)),
					Number(a[coluna.dataField].substr(11, 2)),
					Number(a[coluna.dataField].substr(14, 2)),
					Number(a[coluna.dataField].substr(17, 2))
				);
				let compB = new Date(
					Number(b[coluna.dataField].substr(6, 4)),
					Number(b[coluna.dataField].substr(3, 2)) - 1,
					Number(b[coluna.dataField].substr(0, 2)),
					Number(b[coluna.dataField].substr(11, 2)),
					Number(b[coluna.dataField].substr(14, 2)),
					Number(b[coluna.dataField].substr(17, 2))
				);
				return compA < compB
					? -1 * sortModifier
					: compA > compB
					? 1 * sortModifier
					: 0;
			} else {
				return a[coluna.dataField] < b[coluna.dataField]
					? -1 * sortModifier
					: a[coluna.dataField] > b[coluna.dataField]
					? 1 * sortModifier
					: 0;
			}
		};

		// let sort = (a, b) =>
		// 	(a[coluna.dataField] < b[coluna.dataField])
		// 	? -1 * sortModifier
		// 	: (a[coluna.dataField] > b[coluna.dataField])
		// 	? 1 * sortModifier
		// 	: 0;
		dataProvider = dataProvider.sort(sort);
	}

	function tirarOrdenacaoOutrasColunas(colunaOrdenar) {
		for (let i = 0; i < columns.length; i++) {
			if (
				columns[i].dataField &&
				columns[i].dataField != colunaOrdenar.dataField
			) {
				columns[i].ordenado = null;
			}
		}
	}	

	function changeOption(column, registro, index) {
		registro[column.dataField] = column.options[index];
		refresh();
		// if (column.click) {
		// 	column.click(registro, column, this.dataProvider);
		// }
	}

	function clickEditarRegistro(registro, col) {
		if (!registro['edt_'+col.dataField]) {			
			registro['edt_'+col.dataField] = true;			
			refresh();
		}		
	}

	function sairEdicao(registro, col) {		
		registro['edt_'+col.dataField] = false;	
		if (col.change) {
			col.change(registro, col, registro[col.dataField]);
		}
		// refresh();	
	}

	function getLabelFromProvider(provider, labelField, keyField, valor) {		
		let ret = "";
		for (let i = 0; i < provider.length; i++) {
			if (provider[i][keyField] == valor) {
				ret = provider[i][labelField];
				break;
			}
		}
		return ret;
	}
	

	async function loadDataProvider(col, registro) {
	  if (typeof col.customCombo === 'function') {
			if (!registro.provider) {
				registro.provider = await col.customCombo(registro);
				col.provider = registro.provider;
    		}
		} else {
			col.provider = col.customCombo;
		}
	  return col.provider;
	}

</script>

{#if 1 == 2}
	<button class="truncate vgroup hgroup truncate2line hspacer cabecalhoMes diaFora diaHoje diaSelecionado diaSem calendario acao blink"><i /></button>
	<p class="btnMovMes" />
	<SelectSvelte />
	<TextEditor />
	<Calendario />
	<DatePicker />
{/if}

{#if columns && columns.length > 0}
	<div
		style="position: {absolute
			? 'absolute'
			: 'relative'}; top: 0; left: 0; right: 0; bottom: 0; overflow-y: auto;
			   border: 1px solid #d3d3d3; border-radius: 3px;
			   background-color: #fff; margin: {smaller ? '0' : '3px'};"
		on:scroll={(e) => onScroll(e)}
	>
		<table style="width: 100%;">
			{#if showHeader}
				<thead>
					<tr>
						{#each columns as col, idxCol}
							{#if col.checkbox && col.selectAll}
								<th
									class="cabecalho truncate"
									data-toogle="tooltip"
									title={col.tooltipText
										? col.tooltipText
										: col.label}
									style="text-align: {col.textAlign
										? col.textAlign
										: 'left'}; width: {col.width
										? col.width
										: 'auto'}; max-width: {col.width
										? col.width
										: 'auto'}; min-width: {col.width
										? col.width
										: 'auto'}; border-left: {idxCol > 0
										? '1px solid #d3d3d3'
										: 'none'}; font-size: {smaller ? 'x-small' : 'normal'}; padding: {smaller ? '4px' : '8px'};"
								>
									<div class="hgroup" style="align-items: center;">
										<input
											type="checkbox"
											on:change={(e) => changeCheckAll(col, e)}
										/>
										<span>{col.label}</span>
									</div>
								</th>
							{:else if col.hideHeader}
								<div style="display: none"></div>
							{:else}
								<th
									class="cabecalho truncate"
									on:click={() => clickColuna(col)}
									data-toogle="tooltip"
									title={col.tooltipText
										? col.tooltipText
										: col.label}
									style="text-align: {col.textAlign
										? col.textAlign
										: 'left'}; width: {col.width
										? col.width
										: 'auto'}; max-width: {col.width
										? col.width
										: 'auto'}; min-width: {col.width
										? col.width
										: 'auto'}; border-left: {idxCol > 0
										? '1px solid #d3d3d3'
										: 'none'}; font-size: {smaller ? 'x-small' : 'normal'}; padding: {smaller ? '4px' : '8px'};"
								>
									<div
										class="hgroup"
										style="align-items: center; justify-content: {col.textAlign
											? col.textAlign
											: 'left'};"
									>
										<div class="truncate">{col.label}</div>
										{#if col.ordenado && col.ordenado == "C"}
											<i
												class="fas fa-angle-down"
												style="margin-left: 10px;"
											/>
										{/if}
										{#if col.ordenado && col.ordenado == "D"}
											<i
												class="fas fa-angle-up"
												style="margin-left: 10px;"
											/>
										{/if}
									</div>
								</th>
							{/if}
						{/each}
					</tr>
				</thead>
			{/if}
			<tbody>
				{#each dataProvider ? dataProvider : [] as registro, idxReg}
					<tr
						on:click={() => clickRegistro(registro, idxReg)}
						on:dblclick={() => duploClique(registro)}
						class:selecionado={registroSelecionado.__id
							? registro.__id == registroSelecionado.__id
							: 1 == 2}
					>
						{#each columns as col, idx}
							{#if col.currency}
								<td
									style="text-align: {col.textAlign
										? col.textAlign
										: 'right'}; border-left: {idx > 0
										? '1px solid #d3d3d3'
										: 'none'}; width: {col.width
										? col.width
										: 'auto'}; max-width: {col.width
										? col.width
										: 'auto'}; min-width: {col.width
										? col.width
										: 'auto'}; white-space: {col.breakWord
										? 'unset'
										: 'no-wrap'}; font-size: {smaller ? 'x-small' : 'normal'}; padding: {smaller ? '4px' : '8px'};"
									data-toogle="tooltip"
									title={col.tooltipText
										? col.tooltipText
										: registro[col.dataField]}
								>
									{registro[col.dataField] != null
										? new Intl.NumberFormat("pt-BR", {
												minimumFractionDigits:
													col.decimais
														? col.decimais
														: 2,
										  }).format(registro[col.dataField])
										: ""}
								</td>
							{:else if col.currencyEditable}
								<td
									style="text-align: {col.textAlign
										? col.textAlign
										: 'right'}; border-left: {idx > 0
										? '1px solid #d3d3d3'
										: 'none'}; width: {col.width
										? col.width
										: 'auto'}; max-width: {col.width
										? col.width
										: 'auto'}; min-width: {col.width
										? col.width
										: 'auto'}; white-space: {col.breakWord
										? 'unset'
										: 'no-wrap'}; font-size: {smaller ? 'x-small' : 'normal'}; padding: {smaller ? '4px' : '8px'};
										background-color: #FFF9C2;"
									data-toogle="tooltip"
									title={col.tooltipText
										? col.tooltipText
										: registro[col.dataField]}
									on:click={() => clickEditarRegistro(registro, col)}	
								>

									{#if registro['edt_'+col.dataField]}
										<InputNumber
											bind:objectSource={registro}
											editField={col.dataField}
											decimals={2}
											on:focusOut={() => sairEdicao(registro, col)}
										/>
									{:else}
										{registro[col.dataField] != null
											? new Intl.NumberFormat("pt-BR", {
													minimumFractionDigits:
														col.decimais
															? col.decimais
															: 2,
											}).format(registro[col.dataField])
											: ""}
									{/if}
								</td>
							{:else if col.decimalEditable}
								<td
									style="text-align: {col.textAlign
										? col.textAlign
										: 'right'}; border-left: {idx > 0
										? '1px solid #d3d3d3'
										: 'none'}; width: {col.width
										? col.width
										: 'auto'}; max-width: {col.width
										? col.width
										: 'auto'}; min-width: {col.width
										? col.width
										: 'auto'}; white-space: {col.breakWord
										? 'unset'
										: 'no-wrap'}; font-size: {smaller ? 'x-small' : 'normal'}; padding: {smaller ? '4px' : '8px'};
										background-color: #FFF9C2;"
									data-toogle="tooltip"
									title={col.tooltipText
										? col.tooltipText
										: registro[col.dataField]}
									on:click={() => clickEditarRegistro(registro, col)}
								>
									{#if registro['edt_'+col.dataField]}
										<InputNumber
											bind:objectSource={registro}
											bind:changeFunction={col.change}
											editField={col.dataField}
											decimals={3}
											on:blur={() => sairEdicao(registro, col)}
										/>
									{:else}
										{registro[col.dataField] != null
											? new Intl.NumberFormat("pt-BR", {
													minimumFractionDigits:
														col.decimais
															? col.decimais
															: 3,
											}).format(registro[col.dataField])
											: ""}
									{/if}
								</td>
							{:else if col.intEditable}
								<td
									style="text-align: {col.textAlign
										? col.textAlign
										: 'right'}; border-left: {idx > 0
										? '1px solid #d3d3d3'
										: 'none'}; width: {col.width
										? col.width
										: 'auto'}; max-width: {col.width
										? col.width
										: 'auto'}; min-width: {col.width
										? col.width
										: 'auto'}; white-space: {col.breakWord
										? 'unset'
										: 'no-wrap'}; font-size: {smaller ? 'x-small' : 'normal'}; padding: {smaller ? '4px' : '8px'};"
									data-toogle="tooltip"
									title={col.tooltipText
										? col.tooltipText
										: registro[col.dataField]}
								>
									<input
										type="number"
										bind:value={registro[col.dataField]}
										min="0"
										step="1"
										on:keypress={validateNumber}
										on:blur={() =>
											onBlurChange(col, registro)}
										style="border-width: 0px; width: 80px; padding-right: 10px;"
									/>
								</td>
							{:else if col.textEditable}
								<td
									style="text-align: {col.textAlign
										? col.textAlign
										: 'right'}; border-left: {idx > 0
										? '1px solid #d3d3d3'
										: 'none'}; width: {col.width
										? col.width
										: 'auto'}; max-width: {col.width
										? col.width
										: 'auto'}; min-width: {col.width
										? col.width
										: 'auto'}; white-space: {col.breakWord
										? 'unset'
										: 'no-wrap'}; font-size: {smaller ? 'x-small' : 'normal'}; padding: {smaller ? '4px' : '8px'};"
									data-toogle="tooltip"
									title={col.tooltipText
										? col.tooltipText
										: registro[col.dataField]}
								>
									<input
										type="text"
										bind:value={registro[col.dataField]}
										maxlength={col.maxLength ? col.maxLength : 100}
										disabled={col.disabled ? col.disabled(registro) : false}
										on:blur={() => col.change ? col.change(registro) : null}
										style="width: -webkit-fill-available; padding: 5px; border: 1px solid #d3d3d3; border-radius: 5px;"
									/>
								</td>
							{:else if col.dateEditable}
								<td
									style="text-align: {col.textAlign
										? col.textAlign
										: 'right'}; border-left: {idx > 0
										? '1px solid #d3d3d3'
										: 'none'}; width: {col.width
										? col.width
										: 'auto'}; max-width: {col.width
										? col.width
										: 'auto'}; min-width: {col.width
										? col.width
										: 'auto'}; white-space: {col.breakWord
										? 'unset'
										: 'no-wrap'}; font-size: {smaller ? 'x-small' : 'normal'}; padding: {smaller ? '4px' : '8px'};
										background-color: #FFF9C2;"
									data-toogle="tooltip"
									title={col.tooltipText
										? col.tooltipText
										: registro[col.dataField]}	
									on:click={() => clickEditarRegistro(registro, col)}								
								>	
									{#if registro['edt_'+col.dataField]}
										<DatePicker
											bind:objectSource={registro}
											editField={col.dataField}										
											on:change={(e) => sairEdicao(registro, col)}
										/>
									{:else}
										{registro[col.dataField]}
									{/if}																									
								</td>
							{:else if col.options}
								<td
									style="border-left: {idx > 0
										? '1px solid #d3d3d3'
										: 'none'}; width: {col.width
										? col.width
										: 'auto'}; max-width: {col.width
										? col.width
										: 'auto'}; min-width: {col.width
										? col.width
										: 'auto'}; white-space: {col.breakWord
										? 'unset'
										: 'no-wrap'}; font-size: {smaller ? 'x-small' : 'normal'}; padding: {smaller ? '4px' : '8px'};"
									data-toogle="tooltip"
									title={col.tooltipText
										? col.tooltipText
										: registro[col.dataField]}
								>
									<div class="hgroup" style="align-items: center; justify-content: center;">
										{#each col.options as opt, i}
											<div class="box" class:box-selected="{registro[col.dataField] == opt}"
												on:click={e => changeOption(col, registro, i)}>
												{ col.labels[i] ? col.labels[i] : opt }
											</div>
										{/each}
									</div>
								</td>
							{:else if col.customCombo}
								<td
									style="border-left: {idx > 0
										? '1px solid #d3d3d3'
										: 'none'}; width: {col.width
										? col.width
										: 'auto'}; max-width: {col.width
										? col.width
										: 'auto'}; min-width: {col.width
										? col.width
										: 'auto'}; min-width: {col.width
										? col.width
										: 'auto'}; white-space: {col.breakWord
										? 'unset'
										: 'no-wrap'}; font-size: {smaller ? 'x-small' : 'normal'}; padding: {smaller ? '4px' : '8px'};
										background-color: #FFF9C2;
										text-align: left;"
									data-toogle="tooltip"
									title={col.tooltipText
										? col.tooltipText
										: registro[col.dataField]}
									on:click={() => clickEditarRegistro(registro, col)}
								>
								{#if registro['edt_' + col.dataField]}
									<div style="width: inherit; max-width: {col.width ? col.width : 'auto'};" use:clickOutside on:click_outside={() => sairEdicao(registro, col)}>
									{#if typeof col.customCombo === 'function'}
										{#if !registro.provider}
											{#await loadDataProvider(col, registro, 'here') then providerData}
												<Combo
													dataProvider={providerData}
													bind:objectSource={registro}
													labelField={col.labelField}
													keyField={col.keyField}
													editField={col.dataField}
													on:change={(e) => sairEdicao(registro, col)}
													disabled={col.disabled ? col.disabled(registro) : false}
												/>
											{/await}
										{:else}
											<Combo
												dataProvider={col.provider}
												bind:objectSource={registro}
												labelField={col.labelField}
												keyField={col.keyField}
												editField={col.dataField}
												on:change={(e) => sairEdicao(registro, col)}
												disabled={col.disabled ? col.disabled(registro) : false}
											/>
										{/if}
									{:else}
										<Combo
											dataProvider={col.customCombo}
											bind:objectSource={registro}
											labelField={col.labelField}
											keyField={col.keyField}
											editField={col.dataField}
											on:change={(e) => sairEdicao(registro, col)}
											disabled={col.disabled ? col.disabled(registro) : false}
										/>
									{/if}
									</div>
								{:else}
									{#if typeof col.customCombo === 'function'}
										{#if !registro.provider}
											{#await loadDataProvider(col, registro, 'there') then providerData}
												{getLabelFromProvider(providerData, col.labelField, col.keyField, registro[col.dataField], 'bla')}
											{/await}
										{:else}
											{getLabelFromProvider(registro.provider, col.labelField, col.keyField, registro[col.dataField], 'bli')}
										{/if}
									{:else}
										{getLabelFromProvider(col.customCombo, col.labelField, col.keyField, registro[col.dataField], 'ble')}
									{/if}
								{/if}								
								</td>
							{:else if col.renderer && col.tooltipTextFunction}
								<td
									style="border-left: {idx > 0
										? '1px solid #d3d3d3'
										: 'none'}; width: {col.width
										? col.width
										: 'auto'}; max-width: {col.width
										? col.width
										: 'auto'}; min-width: {col.width
										? col.width
										: 'auto'}; white-space: {col.breakWord
										? 'unset'
										: 'no-wrap'}; font-size: {smaller ? 'x-small' : 'normal'}; padding: {smaller ? '4px' : '8px'};"
									data-toogle="tooltip"
									title={col.tooltipTextFunction
										? col.tooltipTextFunction(registro, col)
										: registro[col.dataField]}
									on:click={(e) =>
										clickRenderer(registro, col, e)}
								>
									<div
										class="vgroup"
										style="cursor: pointer; align-items: {col.textAlign
											? col.textAlign
											: 'center'}; justify-content: {col.textAlign
											? col.textAlign
											: 'center'};"
									>
										{@html col.renderer(registro, col)}
									</div>
								</td>
							{:else if col.renderer}
								<td
									style="border-left: {idx > 0
										? '1px solid #d3d3d3'
										: 'none'}; width: {col.width
										? col.width
										: 'auto'}; max-width: {col.width
										? col.width
										: 'auto'}; min-width: {col.width
										? col.width
										: 'auto'}; white-space: {col.breakWord
										? 'unset'
										: 'no-wrap'}; font-size: {smaller ? 'x-small' : 'normal'}; padding: {smaller ? '4px' : '8px'};"
									data-toogle="tooltip"
									title={col.tooltipText
										? col.tooltipText
										: registro[col.dataField]}
									on:click={(e) =>
										clickRenderer(registro, col, e)}
								>
									<div
										class="vgroup"
										style="cursor: pointer; align-items: {col.textAlign
											? col.textAlign
											: 'center'}; justify-content: {col.textAlign
											? col.textAlign
											: 'center'};"
									>
										{@html col.renderer(registro, col)}
									</div>
								</td>
							{:else if col.checkbox}
								<td
									style="text-align: center; border-Left: {idx >
									0
										? '1px solid #d3d3d3'
										: 'none'}; width: {col.width
										? col.width
										: 'auto'}; max-width: {col.width
										? col.width
										: 'auto'}; min-width: {col.width
										? col.width
										: 'auto'}; white-space: {col.breakWord
										? 'unset'
										: 'no-wrap'}; font-size: {smaller ? 'x-small' : 'normal'}; padding: {smaller ? '4px' : '8px'};"
									data-toogle="tooltip"
									title={col.tooltipText
										? col.tooltipText
										: registro[col.dataField]}
								>
									<input
										type="checkbox"
										checked={registro[col.dataField] ==
											col.checkbox[0]}
										disabled={col.disabled
											? col.disabled(registro)
											: false}
										on:change={(e) =>
											changeCheck(registro, col, e)}
									/>
								</td>
							{:else if col.tooltipTextFunction}
								<td
									style="text-align: {col.textAlign
										? col.textAlign
										: 'left'}; border-left: {idx > 0
										? '1px solid #d3d3d3'
										: 'none'}; width: {col.width
										? col.width
										: 'auto'}; max-width: {col.width
										? col.width
										: 'auto'}; min-width: {col.width
										? col.width
										: 'auto'}; white-space: {col.breakWord
										? 'unset'
										: 'no-wrap'}; font-size: {smaller ? 'x-small' : 'normal'}; padding: {smaller ? '4px' : '8px'};"
									class="truncate"
									data-toogle="tooltip"
									title={col.tooltipTextFunction
										? col.tooltipTextFunction(registro, col)
										: registro[col.dataField]}
								>
									{registro[col.dataField]
										? registro[col.dataField]
										: ""}
								</td>
							{:else}
								<td
									style="text-align: {col.textAlign
										? col.textAlign
										: 'left'}; border-left: {idx > 0
										? '1px solid #d3d3d3'
										: 'none'}; width: {col.width
										? col.width
										: 'auto'}; max-width: {col.width
										? col.width
										: 'auto'}; min-width: {col.width
										? col.width
										: 'auto'}; white-space: {col.breakWord
										? 'unset'
										: 'no-wrap'}; font-size: {smaller ? 'x-small' : 'normal'}; padding: {smaller ? '4px' : '8px'};"
									class="truncate"
									data-toogle="tooltip"
									title={col.tooltipText
										? col.tooltipText
										: registro[col.dataField]}
								>
									{registro[col.dataField] != null
										? registro[col.dataField]
										: ""}
								</td>
							{/if}
						{/each}
					</tr>
				{:else}
					{#if mostrarMensagemVazio}
						<tr>
							<td colspan={columns.length}>
								<div
									style="padding: 10px; background-color: #f0f0f0; color: #646464; font-size: medium; text-align: center; font-size: {smaller ? 'x-small' : 'normal'}; padding: {smaller ? '4px' : '8px'};"
								>
									{mensagemVazio}
								</div>
							</td>
						</tr>
					{/if}
				{/each}
				<!-- {#if mostrarMensagemVazio && (!dataProvider || dataProvider.length == 0)}
					<tr>
						<td style="colspan={columns.length}">
							<div style="padding: 10px; background-color: #f0f0f0; color: #646464; font-size: medium; text-align: center;">
								{mensagemVazio}
							</div>
						</td>
					</tr>
				{/if} -->
			</tbody>
		</table>
	</div>
{/if}

<style>
	@import "./assets/fontawesome/css/all.css";
	/* @import "/assets/bootstrap/css/bootstrap.min.css"; */

	table {
		border-spacing: 0;
	}
	tr {
		cursor: pointer;
	}

	tr:hover {
		background-color: #f0f0f0;
		color: #000;
	}
	th {
		padding: 8px;
		border: none;
		border-bottom: 1px solid #d3d3d3;
		color: #333;
		position: sticky;
		top: 0;
		background-color: rgb(233, 233, 233);
		z-index: 100000000;
	}

	th:hover {
		background-color: #f0f0f0;
	}

	td {
		padding: 8px;
		border: none;
		border-bottom: 1px solid #f0f0f0;
	}	

	.selecionado {
		background-color: rgb(123, 174, 255);
	}

	.selecionado:hover {
		background-color: rgb(123, 174, 255);
	}

	.hgroup {
		display: flex;
		flex-direction: row;
	}

	.vgroup {
		display: flex;
		flex-direction: column;
	}

	.truncate {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.truncate2line {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	button {
		background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
		background-color: rgba(0, 0, 0, 0);
		border: none;
		padding: 0;
		flex: 1;
		height: 100%;
		outline: none;
		font-size: 12px;
		cursor: pointer;
	}

	i {
		text-align: center;
	}

	p {
		margin: 0;
	}

	div {
		text-align: center;
	}

	.hspacer {
		flex: 1;
	}
	/* .cabecalho {
		background-color: #f0f0f0;
	} */
	.cabecalhoMes {
		background-color: #d3d3d3;
		padding: 5px 0;
		justify-content: center;
		width: 100%;
		font-weight: bold;
		align-items: center;
		font-size: small;
	}
	.calendario {
		position: relative;
		top: 0px;
		left: 0px;
		border: 1px solid #d3d3d3;
		border-radius: 3px;
		background-color: #fff;
	}
	.diaSem {
		width: 35px;
		text-align: center;
		padding: 5px 0;
		margin: 5px;
		cursor: pointer;
		font-size: small;
	}
	.diaFora {
		width: 35px;
		text-align: center;
		padding: 5px 0;
		margin: 5px;
		cursor: pointer;
		color: #d3d3d3;
		font-size: small;
	}
	.diaHoje {
		width: 35px;
		text-align: center;
		padding: 5px 0;
		margin: 5px;
		cursor: pointer;
		color: royalblue;
		border: 1px solid royalblue;
		font-size: small;
	}
	.diaSelecionado {
		width: 35px;
		text-align: center;
		padding: 5px 0;
		margin: 5px;
		cursor: pointer;
		color: #fff;
		background-color: royalblue;
		font-size: small;
	}
	.btnMovMes {
		background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
		background-color: rgba(0, 0, 0, 0);
		border: none;
		outline: none;
	}
	.acao {
		font-weight: bold;
		font-size: x-small;
		background-color: red;
		padding: 3px 8px;
		border-radius: 3px;
		color: white;
		margin-right: 3px;
	}

	.blink {
		animation: blinker 1s linear infinite;
	}

	.box {
		height: 20px;
		background-color: #d3d3d3;
		border-radius: 3px;
		margin: 2px;
		padding: 2px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		min-width: 20px;
	}

	.box-selected {
		background-color: darkslateblue;
		color: white;
	}

	::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}

	::-webkit-scrollbar-thumb {
		background: #d3d3d3;
	}

	/* ::-webkit-scrollbar-button:single-button {
		background-color: #f1f1f1;
		display: block;
		border-style: solid;
		height: 10px;
		width: 10px;
	} */

	@keyframes blinker {
		50% {
			opacity: 0;
		}
	}

</style>
