<script>
    import { onMount } from 'svelte'; 
    import jQuery from 'jquery';    
    // import 'https://cdn.jsdelivr.net/npm/summernote@0.8.18/dist/summernote.min.js';        

    let editor;
    export let configuracoes = {
        toolbar: [
            ['style', ['style']],
            ['font', ['bold', 'underline', 'clear']],
            ['fontname', ['fontname']],
            ['color', ['color']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['table', ['table']],
            ['insert', ['link', 'picture', 'video']],
            ['view', ['codeview', 'help']],
        ],
        shortcuts: false,
        backColor: 'white'
    };

    onMount(() => {       
        console.log("ON MOUNT");         
        window.jQuery = jQuery;                                               
        jQuery(editor).summernote(configuracoes);
    });    

</script>


<style>
    @import './assets/summernote/summernote-lite.min.css';    

    .editor {
        background-color: #fff;
    }
</style>

<div class="editor">
    <div bind:this={editor}>
    </div>    
</div>


<svelte:options tag="svelte-text-editor" />
