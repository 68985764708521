<script>
    import { onDestroy, onMount } from "svelte";


    export let decimals = 2;
    export let placeholder = '0,00';
    export let readonly = false;
    export let disabled = false;
    export let objectSource = {};
    export let editField = '';
    export let value;    
    export let maxLength = 18;
    export let changeFunction = null;


    let valorAnterior;
    let texto = null;
    let valorInterno;

    onMount(async () => {
        changeValor();
    });

    onDestroy(() => {
		
	});

    function onBlur() {
        if (valorInterno) {
            if (valorInterno.includes('.')) {
                valorInterno = valorInterno.split('.').join('');
            }
            valorInterno = new Intl.NumberFormat('pt-BR', {minimumFractionDigits: decimals}).format(Number(Number(valorInterno.replace(',', '.')).toFixed(Number(decimals))));
            //new DecimalPipe('pt-br').transform(Number(Number(valorInterno.replace(',', '.')).toFixed(Number(decimals))), '1.' + decimals + '-' + decimals);
            // focusOut.emit();
        }
        if (changeFunction){
            changeFunction(objectSource);
        }
    }    

    function onFocus(event) {
        valorInterno = valorInterno ? valorInterno.split('.').join('') : null;
        event.target.select();
        // edInput.nativeElement.select();
        // setTimeout(() => {
        //     edInput.nativeElement.setSelectionRange(0, edInput.nativeElement.value.length);
        // }, 100);
    }

    function validateNumber(e) {
        let code = e.keyCode || e.which;

        if (code === 13) {
            return;
        }

        let input = String.fromCharCode(e.charCode);
        const reg = /^[0-9,]+$/;

        if (
            (!reg.test(input))
            ||
            ((e.target.value.match(/,/g) || []).length > 0 && e.charCode === 44)
            ||
            (e.target.value.length === 0 && e.charCode === 44)
            ) {
            e.preventDefault();
        } else {
            if (valorInterno && valorInterno.length >= maxLength) {
                return false;
            }
            texto = Number(e.target.value.replace(',', '.')).toFixed(Number(decimals));
        }
    }

    export function setFocus() {
        // if (edInput && edInput.nativeElement) {
        //     edInput.nativeElement.focus();
        // }
    }

    function changeValor() {
        console.log("change valor", objectSource, editField, valorInterno);
        if (valorInterno != null && valorInterno.trim().length > 0) {
            let strValor = valorInterno;
            if (strValor.indexOf(",") >= 0) {
                strValor = strValor.replace(".","");
                strValor = strValor.replace(",",".");
            }
            if (objectSource && editField) {
                objectSource[editField] = Number(Number(strValor).toFixed(Number(decimals)));
            }
            value = Number(Number(strValor).toFixed(Number(decimals)));
        } else {    
            if (objectSource && editField) {
                objectSource[editField] = 0;
            }
            value = 0;        
        }
        console.log(objectSource[editField]);
        // changeValue.emit(value);
    }

    $: if (objectSource && editField) {
        if (objectSource[editField] && objectSource[editField] != valorAnterior) {     
            valorAnterior = objectSource[editField].toString();      
            if (valorInterno) {
                if (valorInterno != objectSource[editField]) {
                    valorInterno = new Intl.NumberFormat('pt-BR', {minimumFractionDigits: decimals}).format(Number(objectSource[editField]).toFixed(Number(decimals)));                    
                }
            } else {
                valorInterno = new Intl.NumberFormat('pt-BR', {minimumFractionDigits: decimals}).format(Number(objectSource[editField]).toFixed(Number(decimals)));                    
            }
            // if (valorInterno != null) {
            //     onBlur();
            // }
        } else {      
            if (objectSource[editField] != valorAnterior) {
                valorInterno = "0,00";
                valorAnterior = 0;
            }
        }
    }

</script>

<style>        
    input:disabled {
        background-color: #eaeaea;
    }

</style>

<input    
    style="text-align: right; padding-right: 5px; width: -webkit-fill-available; text-align: right; border: none; border-radius: 3px;"
    type="tel"
    {placeholder}
    on:blur={onBlur}
    on:focus={onFocus}
    on:keypress={validateNumber}
    on:change={changeValor}
    bind:value={valorInterno}
    {readonly}
    {disabled}
    data-toggle="tooltip"
    title={value}
    maxlength={maxLength - decimals - 1} 
/>

<svelte:options tag="svelte-inputnumber" />
